//site
$primary-h: 3 !default;
$primary-s: 81 !default;
$primary-b: 44 !default;

$primary: #cb1d15;
// $primary-text: white;
// $primary-link: $primary;
// $secondary: #006264;

$tertiary: #636d88;
//$tertiary-text: $white;

//$header-bg-color: $monet-blue;
//$header-center-bg-color: $header-bg-color;

//$headings-color: $primary;
//$semibold: 600;

@import '../variables';

.it-header-slim-wrapper {
  background-color: #636d88;
}

.it-header-wrapper .it-header-center-wrapper.it-header-center-wrapper {
  background-color: white;

  .it-brand-wrapper {
    svg,
    img {
      width: auto;
      height: 70px;
    }

    figure {
      margin: 0 2em 0 0;
    }
  }

  .it-brand-text {
    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }

    h3 {
      text-transform: uppercase;
    }
  }

  .it-header-center-content-wrapper {
    .it-brand-wrapper a,
    .it-right-zone,
    .it-right-zone .it-socials ul .icon {
      color: #3a3a3a;
      fill: #3a3a3a;
    }

    .it-search-wrapper a.rounded-icon {
      background-color: $primary;

      svg {
        fill: white;
      }

      &:focus,
      &:hover {
        background: darken(#3a3a3a, 10%);
      }
    }
  }
  @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    .it-brand-wrapper {
      svg,
      img {
        height: 95px;
      }
    }
  }
}

.it-header-navbar-wrapper {
  background-color: $primary;

  .navbar .navbar-collapsable .menu-wrapper .it-brand-wrapper {
    svg,
    img {
      width: auto;
      height: 60px;
    }
  }
}
.it-footer .it-footer-main {
  .it-brand-wrapper {
    svg,
    img {
      width: auto;
      height: 70px;
    }

    figure {
      margin: 0 1em 0 0;
    }
    @media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      svg,
      img {
        height: 95px;
      }
    }
  }
}
